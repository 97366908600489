// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-items-container::-webkit-scrollbar {
  display: none;
}

/* Hide the scrollbar in Firefox */
.navigation-items-container {
  scrollbar-width: none;
}

@media screen and (max-width: 45em) {
  .dn-sm {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/header/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA,kCAAA;AACA;EACE,qBAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[".navigation-items-container::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide the scrollbar in Firefox */\n.navigation-items-container {\n  scrollbar-width: none;\n}\n\n@media screen and (max-width: 45em) {\n  .dn-sm {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
