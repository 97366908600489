// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-ctr-top {
  padding-top: 3.43rem;
  margin-bottom: 2.5rem;
  gap: 5rem;
}
@media screen and (max-width: 30em) {
  .footer-ctr-top {
    gap: 2rem;
    margin-bottom: 1rem;
  }
}
.footer-ctr-top p {
  max-width: 600px;
}
@media screen and (min-width: 30em) and (max-width: 60em) {
  .footer-ctr-top a {
    text-align: right;
  }
}

.menuItems {
  display: flex;
  display: row;
}
@media screen and (max-width: 60em) {
  .menuItems {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
}

.socials {
  gap: 0.75rem;
}

.react-tooltip#shareProjectTooltip {
  border: 1px solid #2c3038;
}

.react-tooltip__place-top#shareProjectTooltip > .react-tooltip-arrow {
  border-bottom: 1px solid #2c3038;
  border-right: 1px solid #2c3038;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/styles.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,qBAAA;EACA,SAAA;AACF;AACE;EALF;IAMI,SAAA;IACA,mBAAA;EAEF;AACF;AAAE;EACE,gBAAA;AAEJ;AAEI;EADF;IAEI,iBAAA;EACJ;AACF;;AAGA;EACE,aAAA;EACA,YAAA;AAAF;AAEE;EAJF;IAKI,sBAAA;IACA,8BAAA;IACA,mBAAA;IACA,SAAA;EACF;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,gCAAA;EACA,+BAAA;AACF","sourcesContent":[".footer-ctr-top {\n  padding-top: 3.43rem;\n  margin-bottom: 2.5rem;\n  gap: 5rem;\n\n  @media screen and (max-width: 30em) {\n    gap: 2rem;\n    margin-bottom: 1rem;\n  }\n\n  p {\n    max-width: 600px;\n  }\n\n  a {\n    @media screen and (min-width: 30em) and (max-width: 60em) {\n      text-align: right;\n    }\n  }\n}\n\n.menuItems {\n  display: flex;\n  display: row;\n\n  @media screen and (max-width: 60em) {\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    gap: 1rem;\n  }\n}\n\n.socials {\n  gap: 0.75rem;\n}\n\n.react-tooltip#shareProjectTooltip {\n  border: 1px solid #2c3038;\n}\n\n.react-tooltip__place-top#shareProjectTooltip > .react-tooltip-arrow {\n  border-bottom: 1px solid #2c3038;\n  border-right: 1px solid #2c3038;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
