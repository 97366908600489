// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.translate-icon-btm {
  transform: rotate(180deg);
}`, "",{"version":3,"sources":["webpack://./src/components/horizontalScroll/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF","sourcesContent":[".translate-icon-btm {\n  transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
