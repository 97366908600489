// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginator-btn-ctr {
  padding: 1.5rem 0;
  gap: 0.75rem;
}

.paginator-btn {
  padding: 6px 11px;
}`, "",{"version":3,"sources":["webpack://./src/components/paginator/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".paginator-btn-ctr {\n  padding: 1.5rem 0;\n  gap: 0.75rem;\n}\n\n.paginator-btn {\n  padding: 6px 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
