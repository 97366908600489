// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Color palette */
.top-banner-container a {
  color: #d73f3f;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/_variables.scss","webpack://./src/components/banner/styles.scss"],"names":[],"mappings":"AAAA,kBAAA;ACGE;EACE,cDHE;ECIF,qBAAA;AADJ","sourcesContent":["/* Color palette */\n$red: #d73f3f;\n$red-light: #ffeded;\n$orange: #faa71e;\n$blue-dark: #2c3038;\n$blue-light: #929db3;\n$blue-grey: #68707f;\n$washed-blue: rgba(146, 157, 179, 0.1);\n$grey-light: #e1e0e0;\n$tan: #f0efef;\n$white: #fff;\n","@import '../../assets/styles/variables';\n\n.top-banner-container {\n  a {\n    color: $red;\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
